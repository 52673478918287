import axios from 'axios';

const axios1 = axios.create({
	baseURL: 'https://api.vsimtelecom.com/api',
	// baseURL: 'http://localhost:2054/api',
});
const getToken = () => localStorage.getItem('token');
const api = {
	check: () =>
		axios1.get('/admin/check', {
			headers: { Authorization: `Bearer ${getToken()}` },
		}),
	login: (username, password) =>
		axios1.post('/admin/login', { username, password }),
	channels: () =>
		axios1.get('/admin/channels', {
			headers: { Authorization: `Bearer ${getToken()}` },
		}),
	countries: () =>
		axios1.get('/admin/countries', {
			headers: { Authorization: `Bearer ${getToken()}` },
		}),
	countriesWithPrices: () =>
		axios1.get('/admin/countriesWithPrices', {
			headers: { Authorization: `Bearer ${getToken()}` },
		}),
	ratingAndBalances: () =>
		axios1.get('/admin/ratingAndBalances', {
			headers: { Authorization: `Bearer ${getToken()}` },
		}),
	dailyLog: () =>
		axios1.get('/admin/dailyLog', {
			headers: { Authorization: `Bearer ${getToken()}` },
		}),
	giveFree: (deviceId) =>
		axios1.post(
			`/admin/giveFree/${deviceId}`,
			{},
			{
				headers: { Authorization: `Bearer ${getToken()}` },
			}
		),
};

export default api;
