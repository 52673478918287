import { useEffect, useState } from 'react';
import api from './api';
import './index.css';
import moment from 'moment';

function App() {
	const [logined, setLogined] = useState(false);
	const [username, setUserName] = useState('');
	const [password, setPassword] = useState('');
	const [countries, setCountries] = useState([]);
	const [channels, setChannels] = useState([]);
	const [countryWithQtyAndPrices, setCountryWithQtyAndPrices] = useState([]);
	const [active, setActive] = useState(1);
	const [selectedCountry, setSelectedCountry] = useState();
	const [selectedChannel, setSelectedChannel] = useState();
	const [ratingAndBalances, setRatingAndBalances] = useState([]);
	const [dailyLog, setDailyLog] = useState([]);
	const [sorts, setSorts] = useState({});
	const [deviceId, setDeviceId] = useState('');
	useEffect(() => {
		const fetchData1 = async () => {
			try {
				const check = await api.check();
				console.log(check.data);
				if (!check.data.error) {
					setLogined(true);
				}
			} catch (err) {}
		};
		fetchData1();
	}, []);
	useEffect(() => {
		if (logined) {
			if (active === 1) fetchCountryWithQtyAndPrices();
			else if (active === 2) fetchRatingAndBalances();
			else if (active === 3) fetchDailyLog();
		}
	}, [logined, active]);
	const login = async () => {
		const login = await api.login(username, password);
		if (login.data.token) {
			setLogined(true);
			localStorage.setItem('token', login.data.token);
		} else alert('Login failed');
	};
	const fetchCountryWithQtyAndPrices = async () => {
		const { data: channels } = await api.channels();
		const { data: countries } = await api.countries();
		const { data: countryWithQtyAndPrices } = await api.countriesWithPrices();
		setChannels(channels.results);
		setCountries(countries.results);
		setCountryWithQtyAndPrices(countryWithQtyAndPrices.results);
	};
	const fetchRatingAndBalances = async () => {
		const { data: ratingAndBalances } = await api.ratingAndBalances();
		setRatingAndBalances(ratingAndBalances.results);
	};
	const fetchDailyLog = async () => {
		const { data: dailyLog } = await api.dailyLog();
		setDailyLog(dailyLog.results);
	};
	const giveFree = async () => {
		try {
			const { data: give } = await api.giveFree(deviceId);
			if (give.error) return alert(`Cannot give, because ${give.message}`);
			setDeviceId('');
			alert(`Free given to ${deviceId}`);
		} catch (err) {
			alert('Cannot given');
		}
	};
	let countryWithQtyAndPricesResetted = countryWithQtyAndPrices.filter(
		(item) => {
			if (selectedCountry && item.countryCode !== selectedCountry) return false;
			if (selectedChannel && item.channel !== selectedChannel) return false;
			return true;
		}
	);
	if (Object.keys(sorts).length > 0) {
		for (const key of Object.keys(sorts)) {
			const keyBool = sorts[key];
			const [leftNumber, rightNumber] = keyBool ? [-1, 1] : [1, -1];
			countryWithQtyAndPricesResetted = countryWithQtyAndPricesResetted.sort(
				(x, y) => (x[key] > y[key] ? leftNumber : rightNumber)
			);
		}
	}
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{!logined ? (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						alignItems: 'center',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '300px',
						}}
					>
						<h1>Login</h1>
						<input
							type="text"
							value={username}
							onChange={(e) => setUserName(e.target.value)}
						/>
						<input
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<button onClick={() => login()}>Login</button>
					</div>
				</div>
			) : (
				<>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<a href="#" onClick={() => setActive(1)}>
							Countries
						</a>
						__|__
						<a href="#" onClick={() => setActive(2)}>
							SmsPva
						</a>
						__|__
						<a href="#" onClick={() => setActive(3)}>
							Daily Log
						</a>
						__|__
						<a href="#" onClick={() => setActive(4)}>
							Give Free
						</a>
						__|__
						<a
							href="#"
							color="red"
							onClick={() => {
								localStorage.removeItem('token');
								setLogined(false);
							}}
						>
							Logout
						</a>
					</div>
					{active === 1 ? (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								Countries:
								<select
									value={selectedCountry}
									onChange={(e) => setSelectedCountry(e.target.value)}
								>
									<option value={''}>Select Country</option>
									{countries.map((item, key) => (
										<option key={key} value={item.countryCode}>
											{item.name}
										</option>
									))}
								</select>
								Channels:
								<select
									value={selectedChannel}
									onChange={(e) => setSelectedChannel(e.target.value)}
								>
									<option value={''}>Select Channel</option>
									{channels.map((item, key) => (
										<option key={key} value={item}>
											{item}
										</option>
									))}
								</select>
							</div>
							<table>
								<thead>
									<tr>
										<td>Order</td>
										<td onClick={() => setSorts({ countryCode: !sorts.countryCode })}>
											Country
										</td>
										<td onClick={() => setSorts({ channel: !sorts.channel })}>Channel</td>
										<td onClick={() => setSorts({ smspvaQty: !sorts.smspvaQty })}>
											Smspva Qty
										</td>
										<td onClick={() => setSorts({ smspvaPrice: !sorts.smspvaPrice })}>
											Smspva Price
										</td>
										<td onClick={() => setSorts({ updatedAt: !sorts.updatedAt })}>
											Last Update Time
										</td>
									</tr>
								</thead>
								<tbody>
									{countryWithQtyAndPricesResetted.map((item, key) => (
										<tr key={key}>
											<td>{key + 1}</td>
											<td>{item.name}</td>
											<td>{item.channel}</td>
											<td>{item.smspvaQty}</td>
											<td>{item.smspvaPrice}</td>
											<td>{moment(item.updatedAt).format('DD/MM/YYYY HH . mm . SS')}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						''
					)}
					{active === 2 ? (
						<div>
							<table>
								<thead>
									<tr>
										<td>Username</td>
										<td>Key</td>
										<td>Rating</td>
										<td>Balance</td>
										<td>Password</td>
										<td>Smspva UserId</td>
										<td>Last Update Time </td>
									</tr>
								</thead>
								<tbody>
									{ratingAndBalances.map((item, key) => (
										<tr key={key}>
											<td>{item.username}</td>
											<td>{item.key}</td>
											<td>{item.rating}</td>
											<td>{item.balance}</td>
											<td>{item.password}</td>
											<td>{item.smsPvaUserId}</td>
											<td>{moment(item.updatedAt).format('DD/MM/YYYY HH . mm . SS')}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						''
					)}
					{active === 3 ? (
						<div>
							<table>
								<thead>
									<tr>
										<td>Timeouted</td>
										<td>Total</td>
										<td>Date</td>
									</tr>
								</thead>
								<tbody>
									{dailyLog.map((item, key) => (
										<tr key={key}>
											<td>{item.timeouts}</td>
											<td>{item.total}</td>
											<td>{moment(item.createdAt).format('DD/MM/YYYY HH . mm . SS')}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						''
					)}
					{active === 4 ? (
						<div style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
							<h1>Give Free</h1>
							<div>
								Device Id:{' '}
								<input
									type="text"
									value={deviceId}
									onChange={(e) => setDeviceId(e.target.value)}
								/>
							</div>
							<button onClick={giveFree}> Give Free</button>
						</div>
					) : (
						''
					)}
				</>
			)}
		</div>
	);
}

export default App;
